.globalContainer {
  margin: auto;
}

/* Start Responsive Containers */

/* Small Screens */
@media (min-width: 767px) {
  .globalContainer {
    width: 750px;
  }
}

/* Medium Screens */
@media (min-width: 992px) {
  .globalContainer {
    width: 970px;
  }
}

/* Large Screens */
@media (min-width: 1200px) {
  .globalContainer {
    width: 1170px;
  }
}

/* End Responsive Containers */

.Icon {
  transition: 0.3s;
  cursor: pointer;
  transform: scale(1.5);
}

.Icon:hover {
  transform: translateY(-2px) scale(1.55);
}

.Logo:hover {
  color: blue;
}

.separator {
  width: 100vw;
  border-bottom: 2px solid #1e202c;
  margin-bottom: 30px;
}

.sideSection {
  border: 2px solid #1e2955;
  border-radius: 30px;
  padding: 20px 30px;
  width: 100%;
  margin: 10px 0;
}

.mainSection {
  border: 2px solid #1e202c;
  border-radius: 30px;
  padding: 20px 30px;
  width: 100%;
  margin: 15px 0;
}

.mainPage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 900px) {
  .Icon {
    transform: scale(1.3);
  }
}

@media (max-width: 767px) {
  .sideSection {
    display: none;
  }

  .mainSection {
    width: 100%;
  }

  .mainPage {
    display: block;
    margin: 0 10px;
  }

  .separator {
    margin-bottom: 0;
  }

  .Icon {
    transform: scale(1.2);
  }
}

@media (max-width: 450px) {
  .Icon {
    transform: scale(1);
  }
}
