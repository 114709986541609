.header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.imgHolder {
  width: 8%;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.imgHolder img {
  width: 100%;
  height: 100%;
}

.infoHolder {
  justify-content: flex-end;
  width: 85%;
  align-self: center;
}

.infoHolder h2 {
  font-size: 1.5rem;
  padding: 4px 0;
}

.infoHolder h3 {
  color: rgb(152, 152, 152);
}

.header img {
  cursor: pointer;
}

.icon {
  filter: invert(1);
}

@media (max-width: 992px) {
  .imgHolder {
    width: 10%;
  }

  .infoHolder h2 {
    font-size: 1.2rem;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.postText {
  padding: 20px 0;
  font-size: 2rem;
}

.postImg {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
}

.postImg img {
  width: 100%;
  height: 100%;
}

.reactions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}

.reaction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reaction img {
  padding: 0 10px 0 0;
}

.reaction h2 {
  font-size: 1.5rem;
}

.separator {
  outline: 0;
  border: 1px solid #1e202c;
  margin-bottom: 10px;
}

.footerHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.footerImg {
  width: 7%;
  border-radius: 50%;
  overflow: hidden;
}

.footerImg img {
  width: 100%;
  height: 100%;
}

.comment {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #101322;
  width: 80%;
  border: 2px solid #1e202c;
  border-radius: 30px;
  margin: 0 0 0 20px;
  padding: 6px 15px;
  width: 100%;
}

.comment input {
  outline: 0;
  border: 0;
  background-color: transparent;
  width: 100%;
}

.comment img {
  font-size: 1.5rem;
  padding: 5px 9px;
  cursor: pointer;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .imgHolder {
    width: 18%;
  }

  .infoHolder h2 {
    font-size: 1.1rem;
  }

  .header i {
    font-size: 1.8rem;
  }

  .postText {
    padding: 10px 0;
    font-size: 1.7rem;
  }

  .reaction i {
    font-size: 1.2rem;
    padding: 0 5px 0 0;
  }

  .reaction h2 {
    font-size: 1.3rem;
  }
}
