.grouper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 22%;
}

@media (max-width: 767px) {
  .grouper {
    width: 100%;
  }
}
