* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 10px;
  color: white;
  font-family: arial, sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #080b14;
  width: 100vw;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}
