.pictureArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.picture {
  width: 19%;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.pictureArea h2 {
  font-size: 1.8rem;
}

.picture img {
  width: 100%;
  height: 100%;
}

.sideItems {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-top: 19px;
  transition: 0.3s;
}

.sideItems:hover {
  transform: translateY(-2px);
}

.icon {
  filter: invert(1);
}

.sideItems img {
  padding: 0 15px 0 0;
}

.sideItems h2 {
  font-size: 1.5rem;
}

@media (max-width: 992px) {
  .picture {
    width: 25%;
  }
  .pictureArea h2 {
    font-size: 1.2rem;
  }

  .sideItems h2 {
    font-size: 1.1rem;
  }
}
