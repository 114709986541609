.holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.holder input {
  width: 91%;
  outline: 0;
  background-color: #101322;
  border: 2px solid #1e202c;
  border-radius: 30px;
  padding: 12px 15px;
}

.picHolder {
  width: 9%;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.picHolder img {
  width: 100%;
  height: 100%;
}

.separator {
  outline: 0;
  border: 1px solid #1e202c;
  margin-bottom: 10px;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.option {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.option img {
  font-size: 1.7rem;
  padding: 0 10px;
}

.icon {
  filter: invert(1);
}

.option h2 {
  font-size: 1.3rem;
}

@media (max-width: 992px) {
  .picHolder {
    width: 15%;
  }

  .holder input {
    border-radius: 30px;
    padding: 8px 13px;
  }

  .option i {
    font-size: 1.3rem;
    padding: 0 10px;
  }

  .option h2 {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .holder input {
    border-radius: 30px;
    padding: 8px 11px;
  }

  .option i {
    font-size: 1rem;
    padding: 0 5px;
  }

  .option h2 {
    font-size: 0.7rem;
  }
}
