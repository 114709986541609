.grouper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 22%;
}

/* Start Pages Section */

.pagesHeader {
  font-size: 2.5rem;
  margin-bottom: 14px;
}

.page {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.pagePic {
  width: 30%;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.pageInfo {
  width: 60%;
}

.pagePic img {
  width: 100%;
  height: 100%;
}

.pageName {
  width: 100%;
  font-size: 1.5rem;
}

.pageDesc {
  width: 100%;
  font-size: 1rem;
}

/* End Pages Section */
/* Start Events Section */

.events {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.events img {
  padding: 0 15px 0 0;
  cursor: pointer;
}

.icon {
  filter: invert(1);
}

.events h2 {
  font-size: 1.3rem;
  cursor: pointer;
}

/* End Events Section */
/* Start Friends Section */

.friend {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.picture {
  width: 19%;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.picture img {
  width: 100%;
  height: 100%;
}

.friendName {
  font-size: 1.8rem;
}

@media (max-width: 992px) {
  .pagesHeader {
    font-size: 1.8rem;
  }
  .pagePic {
    width: 50%;
  }

  .pageName {
    width: 50%;
    font-size: 0.9rem;
  }

  .pageDesc {
    width: 100%;
    font-size: 0.6rem;
  }

  .events i {
    font-size: 1.3rem;
  }

  .events h2 {
    font-size: 0.9rem;
  }

  .picture {
    width: 25%;
  }

  .friendName {
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  .grouper {
    width: 100%;
  }
}
