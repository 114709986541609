.options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.option {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.option::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: -10%;
  width: calc(100% + 20px);
  height: 0;
  border-radius: 10px 10px 0 0;
  background-color: yellow;
  transition: 0.2s;
}

.option:hover::after {
  height: 2px;
}

.option img {
  padding: 2px 7px;
}

.optionName {
  font-size: 1.3rem;
}

.separator {
  outline: 0;
  border: 1px solid #1e202c;
  margin-bottom: 20px;
}

.createStory {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 23%;
  border: 2px solid yellow;
  border-radius: 10px;
  overflow: hidden;
  height: 200px;
  position: relative;
}

.icon {
  filter: invert(1);
}

.createStory img {
  width: 100%;
  height: 80%;
}

.createStory .holder {
  position: absolute;
  background-color: white;
  color: black;
  border-radius: 50%;
  font-size: 2.7rem;
  width: 28px;
  height: 28px;
  bottom: 25px;
  cursor: pointer;
}

.createStory .holder img {
  width: 100%;
  height: 100%;
}

.createStory h2 {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 5px;
}

@media (max-width: 992px) {
  .createStory {
    width: 23%;
    height: 150px;
  }

  .createStory i {
    font-size: 2.3rem;
    width: 23px;
    height: 23px;
    bottom: 20px;
  }

  .createStory h2 {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .createStory {
    width: 23%;
    height: 90px;
  }

  .createStory i {
    font-size: 1.1rem;
    width: 11px;
    height: 10px;
    bottom: 14px;
  }

  .createStory h2 {
    font-size: 0.7rem;
  }
}

.stories {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.story {
  width: 23%;
  border: 2px solid yellow;
  border-radius: 10px;
  overflow: hidden;
  height: 200px;
  position: relative;
  cursor: pointer;
}

.user {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 40px;
  height: 40px;
  border: 1px solid #787c8a;
  border-radius: 50%;
  overflow: hidden;
}

.user img {
  width: 100%;
  height: 100%;
}

.story img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userName {
  position: absolute;
  bottom: 7px;
  right: 7px;
  margin-bottom: 5px;
  font-size: 1.1rem;
}

@media (max-width: 992px) {
  .story {
    width: 23%;
    height: 150px;
  }

  .user {
    width: 35px;
    height: 35px;
  }

  .userName {
    margin-bottom: 3px;
    font-size: 0.9rem;
  }
}

@media (max-width: 767px) {
  .story {
    width: 23%;
    height: 90px;
  }

  .user {
    width: 20px;
    height: 20px;
  }

  .userName {
    margin-bottom: 1px;
    font-size: 0.7rem;
  }
}
